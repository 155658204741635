import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import {
  seo,
  intro,
  main,
  // about,
  blog,
  upcoming,
  // achievements,
} from '../../../content/omida-bulldogs'
import { useLangContext } from '../../../context/lang.context'
import Intro from '../../../components/Intro'
import Main from '../../../components/Main'

const OmidaBulldogs = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/omida-bulldogs.jpeg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      imageAbout: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/wasiewicz.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      # image1: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image1.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image2: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image2.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image3: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image3.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image4: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image4.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='center'
      />
    </Layout>
  )
}

export default OmidaBulldogs
